import {IWorkflowState} from "./workflow.types";
import {createAction, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Workflow} from "../../services/dsl-service-new/types";

const initialState: IWorkflowState = {
    items: [],
    loading: false,
}

export const workflowSlice = createSlice({
    name: 'workflows',
    initialState,
    reducers: {
        fetchWorkflowItemsStart:(state)=>{
          //state.loading = true
        },
        fetchWorkflowItemsFinish:(state, action:PayloadAction<Workflow[]>)=>{
            state.loading = false
            state.items = action.payload
        },
        fetchWorkflowItemsError:(state)=>{
            state.loading = false
        },
        addWorkflowItem:(state,action:PayloadAction<Workflow>)=>{
            state.items.push(action.payload)
        },
        removeWorkflowItem:(state,action:PayloadAction<string>)=>{
            state.items = state.items.filter(i => i.id !== action.payload)
        },
        updateWorkflowItem:(state,action:PayloadAction<Workflow>)=>{
            state.items = state.items.map(i=>{
                if(i.id === action.payload.id) {
                    return action.payload
                } else {
                    return i
                }
            })
        },
    }
})

const startWorkflowItemsEvents = createAction('workflow/start-workflow-item-events')
const stopWorkflowItemsEvents = createAction('workflow/stop-workflow-item-events')
export const workflowActions = {...workflowSlice.actions, startWorkflowItemsEvents, stopWorkflowItemsEvents }

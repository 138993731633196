import React, {useEffect} from "react";
import {redirect, useNavigate} from "react-router-dom";

const Home:React.FC<{}> = ()=>{
    const nav = useNavigate()
    useEffect(()=>{
        nav("/workflow")
    })
    return null
}

export default Home

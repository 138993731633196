import {all, call, takeLatest, put} from "redux-saga/effects";
import {workflowActions} from "./workflow.slice";
import Service from "../../services/dsl-service-new/service";
import {Workflow} from "../../services/dsl-service-new/types";



function* fetchWorkflowItems() {
    try {
        const response:Workflow[] = yield call(Service.getWorkflows)
        yield put(workflowActions.fetchWorkflowItemsFinish(response))
    } catch (e) {
        yield put(workflowActions.fetchWorkflowItemsError())
    }
}

function* onFetchWorkflowItems() {
    yield takeLatest(workflowActions.fetchWorkflowItemsStart, fetchWorkflowItems)
}

export function* workflowSagas() {
    yield all([
            call(onFetchWorkflowItems)
        ])
}

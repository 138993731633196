import {all, fork} from 'redux-saga/effects'
import {workflowSagas} from "./workflow/workflow.saga";
import {editorSaga} from "./editor/editor.saga";


export default function* rootSaga(): Generator<any, any, any> {
    yield all([
        fork(workflowSagas),
        fork(editorSaga)
    ])
}


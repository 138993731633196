import React, {Suspense, useEffect} from 'react';
import {Route, Routes} from "react-router-dom";


//Import Pages
import Home from "./routes/home.component";
import Error404 from "./routes/error404.component";
import DefaultLayout from "./layout/layout.component";
import Login from "./routes/login.component";
import Logout from "./routes/logout.component";
import {useDispatch} from "react-redux";
import {workflowActions} from "./redux/workflow/workflow.slice";
import socket from "./services/dsl-service-new/socker-io";
import {Workflow} from "./services/dsl-service-new/types";
import {editorActions} from "./redux/editor/editor.slice";
const ProjectsIndex = React.lazy(()=> import("./routes/workflow/workflow-index.component"));
const ProjectSingle = React.lazy(()=> import("./routes/workflow/project-single.component"));


interface IProps {}

const App:React.FC<IProps> = () => {
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(workflowActions.fetchWorkflowItemsStart())
        socket.on('workflow:updated', (e)=>{
            dispatch(workflowActions.updateWorkflowItem(e as Workflow))
        })
        socket.on('workflow:created', (e)=>{
            dispatch(workflowActions.addWorkflowItem(e as Workflow))
        })
        socket.on('workflow:deleted', (e:string)=>{
            dispatch(workflowActions.removeWorkflowItem(e))
        })
        return ()=>{
            socket.removeAllListeners('workflow:updated')
            socket.removeAllListeners('workflow:create')
            socket.removeAllListeners('workflow:deleted')
        }
    },[dispatch])
    return (
        <Routes>
            <Route path='/' element={<DefaultLayout/>}>
                <Route index element={<Home/>}/>
                <Route path='/workflow' >
                    <Route index element={<ProjectsIndex/>}/>
                    <Route path=':workflowId' element={<ProjectSingle/>}/>
                </Route>
            </Route>
            <Route path='/logout' element={<Logout/>}/>
            <Route path='/login' element={<Login/>}/>
            <Route path='*' element={<Error404/>}/>
        </Routes>
    );
}

export default App;

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IEditorState} from "./editor.types";
import {addEdge, applyEdgeChanges, applyNodeChanges, Connection, EdgeChange, NodeChange} from "reactflow";
import {MarketplaceItem, Workflow, WorkflowStatus} from "../../services/dsl-service-new/types";
import {Node} from "reactflow";

const initialState:IEditorState = {
    status: WorkflowStatus.DRAFT,
    nodes:[],
    edges:[],
    loading: false,
    needSave: false,
    marketplaceItems:[],
    loadingMarketplaceItems:false
}
export const editorSlice = createSlice({
    name: "editor",
    initialState,
    reducers:{
        fetchEditorItemStart: (state,action:PayloadAction<string>)=>{
            state.loading = true
        },
        fetchEditorItemFinish: (state,action:PayloadAction<Workflow>)=>{
            console.log(action)
            const tmp = JSON.parse(String(action.payload.flow))
            if(tmp?.edges) {
                state.edges = tmp.edges
            }
            if(tmp?.nodes) {
                state.nodes = tmp.nodes
            }
            state.query = action.payload.query || undefined
            state.meta = action.payload.meta || undefined
            state.loading = false
            state.status = action.payload.status
            state.name = action.payload.name
            state.id = action.payload.id
            //console.log(tmp)
        },
        fetchEditorItemError: (state)=>{
            state.loading = false
        },
        saveEditorStart:(state) => {
            state.loading = true
        },
        saveEditorFinish:(state) => {
            state.loading = false
            state.needSave = false
        },
        saveEditorError:(state) => {
            state.loading = false
        },
        onNodeChanges: (state, action:PayloadAction<NodeChange[]>) => {
            state.nodes = applyNodeChanges(action.payload, state.nodes)
            state.needSave = true
        },
        onNodeDataChanges: (state, action:PayloadAction<{id:string, data:Node<any>["data"]}>) => {
            console.log(action)
            state.nodes = state.nodes.map(i=>{
                if(i.id === action.payload.id) {
                    return {
                        ...i,
                        data:action.payload.data
                    }
                } else {
                    return i
                }
            })
            console.log(state.nodes)
            state.needSave = true
        },
        onEdgeChanges: (state, action:PayloadAction<EdgeChange[]>) => {
            state.edges = applyEdgeChanges(action.payload, state.edges)
            state.needSave = true
        },
        onConnect: (state, action:PayloadAction<Connection>) => {
            state.edges = addEdge(action.payload, state.edges)
            state.needSave = true
        },
        fetchMarketplaceItemsStart: (state) => {
            state.loadingMarketplaceItems = true
        },
        fetchMarketplaceItemsFinish: (state, action:PayloadAction<MarketplaceItem[]>) => {
            state.marketplaceItems = action.payload
            state.loadingMarketplaceItems = false
        },
        fetchMarketplaceItemsError: (state) => {
            state.loadingMarketplaceItems = false
        },
        clearAll: (state) => initialState
    }
})

export const editorActions = editorSlice.actions

import React from "react";
import {Link} from "react-router-dom";

const Error404:React.FC<{}> = ()=>(
    <div className="text-center container flex flex-1 justify-center items-center flex-col">
        <p className="text-base font-semibold text-sky-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-800 sm:text-5xl">Page not found</h1>
        <p className="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking for.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
                to="/"
                className="btn-primary"
            >
                Go back home
            </Link>
        </div>
    </div>
)

export default Error404

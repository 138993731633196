import React, {Fragment} from "react";
import {Link, NavLink} from "react-router-dom";

import {ReactComponent as EO4EULogo} from '../../assets/img/eo4eu_logo_final_w.svg';
import md5 from 'md5'
import {Menu, Transition} from "@headlessui/react";
import {useAuth} from "../../components/auth.context";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

const Header:React.FC<{}> = () => {
    const {isAuthenticated, keycloak, userInfo} = useAuth();
    return(
        <header className="bg-gray-600 text-white shadow-2xl z-50">
            <div className="container py-2">
                <nav className="flex justify-between items-center">
                    <div className="flex items-center gap-8">
                        <div>
                            <Link className="hover:text-gray-300 transition duration-100" to={'/'} title="Home">
                                <EO4EULogo width={92}/>
                            </Link>
                        </div>
                    </div>
                    <div className="flex items-center gap-3">
                        {
                            !isAuthenticated ?
                                <div>
                                    <Link className="btn-white-outline" to={'/login'}>Login</Link>
                                </div> :
                                <>
                                    {/*<NavLink*/}
                                    {/*    to={'workflow'}*/}
                                    {/*    className="btn-white-outline"*/}
                                    {/*>*/}
                                    {/*    Workflow Editor*/}
                                    {/*</NavLink>*/}
                                    <Menu as="div" className="relative">
                                        <Menu.Button className="flex items-center rounded-full bg-white text-sm focus:outline-none focus:ring-0 focus:ring-white focus:ring-offset-1">
                                            <span className="sr-only">Open user menu</span>
                                            <img className="h-8 w-8 rounded-full" src={`https://www.gravatar.com/avatar/${md5(String(userInfo?.email?.trim().toLowerCase()))}.jpg?s=84`} alt="" />
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-200"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute right-0 z-50 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <a
                                                            href={keycloak.createAccountUrl()}
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            Your Profile
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link
                                                            to={'/logout'}
                                                            className={classNames(
                                                                active ? 'bg-gray-100' : '',
                                                                'block px-4 py-2 text-sm text-gray-700'
                                                            )}
                                                        >
                                                            Logout
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                </>
                        }
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header
import axios from "axios";
import {MarketplaceItem, UploadInSituResponse, Workflow} from "./types";
import {AxiosRequestConfig} from "openapi-client-axios";

export const auth = {
    token: ""
}

const client = axios.create({
    baseURL:"https://dsl-api.dev.wekeo.apps.eo4eu.eu/",
})

client.interceptors.request.use(
    (config)=>{
        config.headers.setAuthorization("Bearer " +auth.token, true)
        return config
    }
)

class Service {
    static getWorkflows() {
        return client.get<Workflow[]>('my/workflows').then(r=>r.data)
    }
    static getWorkflowItem(workflowId:string){
        return client.get<Workflow>(`my/workflows/${workflowId}`).then(r=>r.data)
    }
    static startWorkflow(workflowId:string, body:string) {
        const conf: AxiosRequestConfig = {
            headers:{
                "Content-Type" : "text/plain"
            }
        }
        return client.post<boolean>(`my/workflows/${workflowId}/start`, body, conf).then(r=>true)
    }
    static stopWorkflow(workflowId:string) {
        return client.get<boolean>(`my/workflows/${workflowId}/stop`).then(r=>true)
    }
    static deleteWorkflow(workflowId:string) {
        return client.delete<boolean>(`my/workflows/${workflowId}`).then(r=>true)
    }
    static getMarketplace() {
        return client.get<MarketplaceItem[]>('marketplace').then(r=>r.data)
    }
    static uploadInsituData(workflowId:string, file:File, metaData:string) {
        const formData = new FormData()
        formData.append("file", file)
        formData.append("metaData", metaData)
        return client.post<UploadInSituResponse>(`data/insitu/${workflowId}/upload`, formData).then(r=>r.data)
    }

}

export default Service

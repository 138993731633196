export interface Workflow {
    id: string,
    name: string,
    flow: string | null,
    query: string | null,
    meta: string | null,
    createdAt: Date,
    updatedAt: Date,
    public: boolean,
    status: WorkflowStatus,
    users: WorkflowUser[]
}

export interface WorkflowUser{
    id: string
    userId: string,
    role: WorkflowUserRole,
    workflowId: string
}

export enum WorkflowStatus{
    DRAFT = 'DRAFT',
    COMPILING = 'COMPILING',
    COMPILED = 'COMPILED',
    PUBLISHING = 'PUBLISHING',
    PUBLISHED = 'PUBLISHED',
    STOPPING = 'STOPPING',
    STOPPED = 'STOPPED',
    DSL_ERROR = 'DSL_ERROR'
}

export enum WorkflowUserRole {
    OWNER = 'OWNER',
    EDITOR = 'EDITOR',
    VIEWER ='VIEWER'
}

export interface MarketplaceItem {
    id: string,
    name: string,
    type: MarketplaceItemType,
    description?: string,
    icon: string,
    createdAt: Date,
    updatedAt: Date,
    extraData?: string,
}

export enum MarketplaceItemType {
    FAAS = "FAAS",
    ML = "ML",
    FUSION = "FUSION",
    WORKFLOW = "WORKFLOW"
}

export interface UploadInSituResponse {
    dataPath: string,
    metaDataPath: string,
    workflowId: string,
}

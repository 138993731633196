import {PersistConfig, persistReducer} from 'redux-persist';
import {combineReducers} from 'redux';
import storage from 'redux-persist/lib/storage'

import {IWorkflowState} from "./workflow/workflow.types";
import {workflowSlice} from "./workflow/workflow.slice";
import {IEditorState} from "./editor/editor.types";
import {editorSlice} from "./editor/editor.slice";
import workflowNoItems from "../routes/workflow/components/workflow-no-items";
import workflowSide from "../routes/workflow/components/workflow-side";

const globalPersistConfig: PersistConfig<RootState> = {
    key: 'root',
    whitelist: [],
    storage
};


export interface RootState {
    workflow: IWorkflowState,
    editor: IEditorState
}

export const rootReducer = combineReducers<RootState>({
    workflow: workflowSlice.reducer,
    editor: editorSlice.reducer
});


// @ts-ignore
export const persistedReducer = persistReducer<RootState>(globalPersistConfig, rootReducer);
import Header from "./header/header.component";
import {Navigate, Outlet} from "react-router-dom";
import Footer from "./footer/footer.component";
import React, {Suspense} from "react";
import {useAuth} from "../components/auth.context";


const DefaultLayout:React.FC<{}> = () => {
    const auth = useAuth()
    if (auth.isAuthenticated) {
        return (
            <>
                <Header/>
                <main>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Outlet/>
                    </Suspense>
                </main>
                <Footer/>
            </>
        )
    } else {
        return <Navigate to={'/login'}/>
    }
}

export default DefaultLayout;
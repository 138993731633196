import {configureStore, Middleware} from '@reduxjs/toolkit';

import createSagaMiddleware from 'redux-saga';

import rootSaga from './root-saga';
import {persistStore} from 'redux-persist';
import {Persistor} from 'redux-persist/es/types';
import {persistedReducer} from './root-reducer';


const sagaMiddleware = createSagaMiddleware();


const middlewares: Middleware[] = [sagaMiddleware];

export const store = configureStore({
    reducer: persistedReducer,
    middleware: middlewares,
});

export const persistor: Persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

import {createSelector} from "reselect";
import {RootState} from "../root-reducer";
import {atob, btoa} from "buffer";
import MLNode from "../../routes/workflow/components/nodes/ml-node.component";

const selectEditor = (state:RootState) => state.editor

export const selectEditorNodes = createSelector(
    [selectEditor],
    (editor)=>editor.nodes
)

export const selectEditorEdges = createSelector(
    [selectEditor],
    (editor)=>editor.edges
)

export const selectEditorQueryDecoded = createSelector(
    [selectEditor],
    (editor)=> {
        if(editor.query) {
            return window.atob(editor.query)
        } else {
            return ""
        }
    }
)

export const selectEditorQuery = createSelector(
    [selectEditor],
    (editor)=> editor.query
)
export const selectEditorMeta = createSelector(
    [selectEditor],
    (editor)=> editor.meta
)

export const selectEditorName = createSelector(
    [selectEditor],
    (editor)=>editor.name
)

export const selectEditorLoading = createSelector(
    [selectEditor],
    (editor)=>editor.loading
)

export const selectEditorId = createSelector(
    [selectEditor],
    (editor)=>editor.id
)

export const selectEditorStatus = createSelector(
    [selectEditor],
    (editor)=>editor.status
)

export const selectEditorNeedSave = createSelector(
    [selectEditor],
    (editor)=>editor.needSave
)

export const selectEditorMarketplaceItems = createSelector(
    [selectEditor],
    (editor)=>editor.marketplaceItems
)

export const selectDSLString = createSelector(
    [selectEditorNodes, selectEditorEdges, selectEditorQuery, selectEditorMeta],
    (nodes, edges, query,meta) => {
        let dsl =`@define{\n\t@node EO4EU{}\n\t@node FAAS extends EO4EU{\n\t\ttemplate: @string\n\t\tscriptText: @string\n\t\treqText: @string\n\t}\n\t@node CF extends EO4EU{}\n\t@node AR_XR extends EO4EU{}\n\t@node FUSION extends EO4EU{\n\t\talgorithm: @string\n\t}\n\t@node ML extends EO4EU{\n\t\talgorithm: @string\n\t\tnamespace: @string\n\t\tserviceName: @string\n\t}\n\t@node POST_PRO extends EO4EU{}\n\t@node PRE_PRO extends EO4EU{\n\t\tsource: @string\n\t\tmetaInfo: @string\n\t\tinSituData: @string\n\t\tinSituMeta: @string\n\t}\n}`
        dsl += '\n@graph{'
        nodes.forEach(i=>{
            if(i.type === "PRE_PRO") {
                dsl += `\n\t@node ${i.id}(${i.type}){\n\t\tsource:"${window.btoa(String(query))}"\n\t\tmetaInfo:"${meta}"\n\t\tinSituData:"${i.data.extraData.inSituData}"\n\t\tinSituMeta:"${i.data.extraData.inSituMeta}"}`
            } else if(i.type === "FAAS"){
                dsl += `\n\t@node ${i.id}(${i.type}){\n\t\ttemplate:"${i.data.extraData.template}"\n\t\tscriptText:"${window.btoa(i.data.extraData.scriptText)}"\n\t\treqText:"${window.btoa(i.data.extraData.reqText)}"}`
            } else if(i.type === "ML") {
                dsl += `\n\t@node ${i.id}(${i.type}){\n\t\talgorithm:"${i.data.extraData.model}"\n\t\tnamespace:"${i.data.extraData.namespace}"\n\t\tserviceName:"${i.data.extraData.serviceName}"}`
            } else if(i.type === "FUSION") {
                dsl += `\n\t@node ${i.id}(${i.type}){\n\t\talgorithm:"${i.data.extraData.algorithm}"}`
            } else {
                dsl += `\n\t@node ${i.id}(${i.type}){}`
            }
        })
        edges.forEach(i=>{
            dsl += `\n\t@connect{\n\t\t${i.source} -> ${i.target}\n\t}`
        })
        dsl += '\n}'
        return dsl
    }
)

import React from "react";

class Footer extends React.Component<{}, {}>{
    render() {
        return (
            <footer className="bg-gray-100 text-gray-800 py-4">
                <div className="container text-xs">
                    ©2023 All rights reserved | EO4EU
                </div>
            </footer>
        )
    }
}


export default Footer
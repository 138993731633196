import {all, call, put, takeLatest, select} from "redux-saga/effects";
import {editorActions} from "./editor.slice";
import Service from "../../services/dsl-service-new/service";
import {PayloadAction} from "@reduxjs/toolkit";
import {MarketplaceItem, Workflow} from "../../services/dsl-service-new/types";
import {
    selectEditorEdges,
    selectEditorId,
    selectEditorName,
    selectEditorNodes,
    selectEditorQuery
} from "./editor.selectors";
import socket from "../../services/dsl-service-new/socker-io";



function* fetchEditorItem(action:PayloadAction<string>){
    try {
        const response:Workflow = yield call(Service.getWorkflowItem, action.payload)
        console.log(response)
        yield put(editorActions.fetchEditorItemFinish(response))
    } catch (e) {
        yield put(editorActions.fetchEditorItemError())
    }
}

function* saveEditor(){

    try{
        const id: string = yield select(selectEditorId)
        const name: string = yield select(selectEditorName)
        const query: string = yield select(selectEditorQuery)
        const nodes: string = yield select(selectEditorNodes)
        const edges: string = yield select(selectEditorEdges)
        socket.emit('workflow:save', id, {name, query, flow:JSON.stringify({nodes, edges})})
        yield put(editorActions.saveEditorFinish())
    } catch (e) {
        yield put(editorActions.saveEditorError())
    }
}

function *fetchMarketplaceItems() {
    try {
        const items: MarketplaceItem[] = yield call(Service.getMarketplace)
        yield put(editorActions.fetchMarketplaceItemsFinish(items))
    } catch (e) {
        yield put(editorActions.fetchMarketplaceItemsError())
    }
}

function* onFetchEditorItemStart() {
    yield takeLatest(editorActions.fetchEditorItemStart, fetchEditorItem)
}

function* onSaveEditorStart() {
    yield takeLatest(editorActions.saveEditorStart, saveEditor)
}

function* onFetchMarketplaceItemStart() {
    yield takeLatest(editorActions.fetchMarketplaceItemsStart, fetchMarketplaceItems)
}

export function* editorSaga() {
    yield all([
        call(onFetchEditorItemStart),
        call(onSaveEditorStart),
        call(onFetchMarketplaceItemStart)
    ])
}
